import { ThemeProvider } from "@mui/material/styles";
import { GlobalStoreProvider } from "./../../store";
import theme from "./../../theme";
import Router from "./../../router";
import "../../services/i18n";
import { initialiseApplicationMonitoring } from "../../services/application-monitoring-adapter";
import {
  trackAppDistribution,
  trackEvent,
} from "../../services/analytics-adapter";
import {
  getAppDistributionIdentifier,
  getDefaultFontSize,
  getUserAgent,
} from "../../utils";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "../../context/SnackbarProvider";
import DefaultSnackbar from "../DefaultSnackbar";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackWithReload from "../ErrorFallbackWithReload";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const appDistributionIdentifier = getAppDistributionIdentifier(
  window.location.toString()
);
initialiseApplicationMonitoring();
trackAppDistribution(appDistributionIdentifier);

trackEvent({
  event: "action.applicationLaunched",
  userAgent: getUserAgent(),
  "user.browserFontSize": getDefaultFontSize(),
});

export const sharedQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={sharedQueryClient}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallbackWithReload}>
          <GlobalStoreProvider
            appDistributionIdentifier={appDistributionIdentifier}
          >
            <SnackbarProvider>
              <DefaultSnackbar />
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </SnackbarProvider>
          </GlobalStoreProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
