import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { trackEvent } from "../../services/analytics-adapter";

const autoReloadedParamName = "autoReloaded";

function getCurrentApplicationEtag(): Promise<string | null> {
  return window
    .fetch("/index.html", {
      method: "HEAD",
      cache: "no-store",
    })
    .then((response) => {
      return response.headers.get("Etag");
    })
    .catch(() => {
      return "";
    });
}

function isAutoReloadedParamInURL() {
  return window.location.search.indexOf(autoReloadedParamName) > -1;
}

export default function AppVersionChangeController() {
  const location = useLocation();
  const [initialApplicationEtag, setInitialApplicationEtag] = useState("");

  useEffect(() => {
    if (isAutoReloadedParamInURL() === true) {
      trackEvent({
        event: "action.appAutoReloaded",
      });
    }
  }, []);

  useEffect(() => {
    getCurrentApplicationEtag()
      .then((currentApplicationEtag) => {
        if (!initialApplicationEtag) {
          setInitialApplicationEtag(currentApplicationEtag || "");
          return;
        }
        if (
          initialApplicationEtag !== "" &&
          currentApplicationEtag !== "" &&
          initialApplicationEtag !== currentApplicationEtag &&
          isAutoReloadedParamInURL() === false
        ) {
          window.location.search += `&${autoReloadedParamName}=true`;
        }
      })
      .catch(() => {});
  }, [location, initialApplicationEtag]);

  return null;
}
