import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGlobalStore } from "../../store";

export default function HandleExpiredSession() {
  const navigate = useNavigate();
  const { dispatch } = useGlobalStore();

  useEffect(() => {
    dispatch({
      type: "CLEAR_CURRENT_USER",
    });
    navigate("/login");
  }, []);

  return <></>;
}
